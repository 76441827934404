//import logo from './logo.svg';
import './App.css';
import Header from './Header';
import Main from './Main';
import Tokenomics from './tokenomics';
import Grow from './grow';
import Unveil from './Unveil';
import Htb from './Htb';
import Test from './Test';
import Btns from './Btns';

function App() {
  return (
    <div className="App">
      <Header/>
      <Main/>
      <Tokenomics/>
      <Grow/>
      <Htb/>
      <Test/>
      <Btns/>
      <Unveil/>
      
    </div>
  );
}

export default App;
