import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const Btns = () => {
  return (
    <div class="button-container mb-0">
  <button>( Crypto News )</button>
  <button>( Crypto News )</button>
  <button>( Crypto News )</button>
  <button>( Crypto News )</button>
</div>

  )
}

export default Btns;
