// Main.js

import React from 'react';
import './App.css';
import { Row, Col } from 'react-bootstrap';

const Main = () => {
  return (
    <Row className="mt-5">
      {/* First column */}
      <Col md={7} className="center-content" style={{backgroundImage: "url('img/first.svg')", backgroundRepeat: 'no-repeat', backgroundPosition: 'right', backgroundSize: '35%'}}>
        <div >
          <h1 className="text-left">Orangana</h1>
          <p className="text-left">Join the jungle frenzy! Orangana presale: where the orangutan's spirit meets the crypto adventure.</p>
          <img src='img/connect.svg' className='connect' />
        </div>
       
      </Col>
      
      {/* Second column */}
      <Col md={5}>
        <div className='monkey'>
        <img src='img/bigimage.svg' className="img-fluid" alt="Big" />
        </div>
      </Col>
    </Row>
  );
};

export default Main;
