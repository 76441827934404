import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // You can add your custom styles here

function Unveil() {
  return (
    <Container fluid className="p-0" id='Unveil'> {/* Added p-0 class to remove padding */}
      {/* First Row */}
      <Row> {/* Removed margin bottom */}
        {/* First Column */}
        <Col md={8} className="text-center mt-3">
          <Image src="img/unveil.svg" fluid />
        </Col>
        {/* Second Column */}
        <Col md={4}>
          <Image src="img/topmonkey.svg" fluid className="float-left topmonkey" />
        </Col>
      </Row>

      {/* Second Row */}
      <Row> {/* Removed margin bottom */}
        {/* First Column */}
        <Col md={4} className="position-relative">
          <Image src="img/leftmonkey.svg" fluid className="float-left leftmonkey" />
        </Col>
        {/* Second Column */}
        <Col md={8} className="position-relative">
          <Image src="img/signboard.svg" fluid className="float-right signboard" />
          {/* Text over Image */}
          <div className="text-over-image">
            Welcome to Orangana, the innovative meme coin with a heart. Our mission extends beyond profits; we're committed to making a positive impact. With a focus on revolutionizing the meme coin landscape and delivering substantial gains, Orangana also champions charitable causes. A portion of our proceeds goes toward supporting orangutan conservation efforts, reflecting our dedication to environmental stewardship and wildlife preservation. Founded by a team of meme enthusiasts and blockchain experts, our project combines innovation, gains, and charity. Together, let's forge ahead on this collective journey where your involvement is essential. Join us in our commitment to foster a brighter future for meme coins and orangutans alike! <br/>- Orangana Founders
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Unveil;



