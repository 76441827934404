import React from 'react';
import './App.css';
import { Row, Col } from 'react-bootstrap';

const grow = () => {
  return (
    <Row>
    <Col md={6} className="center-contents">
      <h1>Grow with us</h1>
      <h2>Profit with purpose</h2>
      <p>Gaining profit while giving back to nature – that's the essence of a meaningful journey</p>
      <br/>
      <p> Orangana helps you grow your investment while supporting orangutan conservation efforts. Join us in making gains and giving back to nature.</p>
      <a href='#'><img src='img/buy.svg'/></a>
    </Col>
    <Col md={6} className="d-flex align-items-center justify-content-center flex-column">
        <img src='img/monkeygrow.svg' alt="Monkey Grow"/>
        <div className="get-social">
          <h3 className='text-white'>Get Social</h3>
          <div className="social-icons custom-icon">
            <a href="#"><i className="fab fa-facebook"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
      </Col>
    </Row>

  );
  
};
export default grow;