import React from 'react';
import './App.css';
import { Row, Col } from 'react-bootstrap';

const Htb = () => {
  return (
    <Row>
    <Col md={6} className="center-contents">
        <img src='img/htb.svg' className='tokenimg'/>
        <img src='img/monnk.svg' className='tokenimg'/>
    </Col>
    <Col md={6}>        
    <div class="column">
    <div class="overlay-content">
    <h2>Seamless integrations</h2>
    <p>What's special about your product, service, or company? Use this space to highlight the things that set you apart from your competition, whether it's a special feature, a unique philosophy, or awards and recognition that you have received. Think of this as your elevator pitch to get the reader's attention.</p>
    <h2>Intuitive user interface</h2>
    <p>What's special about your product, service, or company? Use this space to highlight the things that set you apart from your competition.</p>
    <h2>Excellent customer support</h2>
    <p>Use this space to highlight the things that set you apart from your competition, whether it's a special feature, a unique philosophy, or awards and recognition that you have received. Think of this as your elevator pitch to get the reader's attention.</p>
    </div>
    <img class="Image" src='img/lgboard.png' alt="Image 3"/>
    </div>
    </Col>
    </Row>

  );
  
};
export default Htb;
