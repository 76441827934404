// tokenomics.js

import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import './App.css'; // Import your custom CSS file

const Tokenomics = () => {
  return (
    <div class='backtree'>
      <Container className='tokenback'>
        {/* Row with background image */}
        <Row className="tokenomics-row tokenomics-row-adjusted" >
          <Col>
          <img src='img/tokenomics.svg' className='tokenimg'/>
            {/* Content for the first row */}
            {/* You can add content here */}
          </Col>
        </Row>
        
        {/* Row with table */}
        <Row className="mt-4 justify-content-center">
          <Col md={4}></Col> 
          <Col md={4}>
            <Table>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Presale</td>
                  <td>32%</td>
                </tr>
                <tr>
                  <td>Project Funds</td>
                  <td>16%</td>
                </tr>
                <tr>
                  <td>Marketing</td>
                  <td>15%</td>
                </tr>
                <tr>
                  <td>DEX/CEX Liquidity</td>
                  <td>15%</td>
                </tr>
                <tr>
                  <td>Community Rewards & Orangutan Charity</td>
                  <td>12%</td>
                </tr>
                <tr>
                  <td>Development Team</td>
                  <td>10%</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md={4}>
            <img src='img/monkeyside.svg' className='monkeyside'/>
            </Col> 
        </Row>
        
     

        <Row className="tokenomics-row tokenomics-row-adjusted" >
          <Col>
          <img src='img/junglemap.svg' className='tokenimg'/>
           
          </Col>
        </Row>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/><br/><br/>
        <Row className="mt-4 justify-content-center">
          <Col md={4} > 
          <div class='oneback'>
          <h3>Path1</h3>
          <p> Pre-sale and Initial Community Building</p>
          <ul>
            <li>Develop concept</li>
            <li>Build social media presence</li>
            <li>Conduct pre-sale for project funding</li>
            <li>Distribute tokens and list on DEX</li>
          </ul>
          </div></Col> {/* Empty column */}
          <Col md={4} >
          <div class='oneback'>
          <h3>Path2</h3>
          <p> DEX Launches and Community Growth</p>
          <ul>
            <li>Intense marketing </li>
            <li>Expand DEX listings</li>
            <li> Create campaigns</li>
            <br/>
          </ul>
            </div>
          </Col>
          <Col md={4} >
            <div class='threeback'>
            <h3>Path3</h3>
          <p>  CEX Launch and Market Cap Growth</p>
          <ul>
            <li>List on centralized exchanges.</li>
            <li>Implement strategies for market cap growth.</li>
            <li>Continue development and ecosystem expansion.</li>
            <br/>
          </ul></div>
            </Col> {/* Empty column */}
        </Row>
        <br/>
        <br/>
        <br/>
      </Container>
    </div>
  );
};

export default Tokenomics;
