import React from 'react'
import './App.css';
import { Row, Col } from 'react-bootstrap';

const Test = () => {
  return (
    <Row>
    <Col md={6} className="center-contents">
        <p className="fs-3">Orangana: A vibrant token on the Solana blockchain, embodying the strength and spirit of orangutans. Join our jungle community and swing into the future of decentralized finance!</p>
      <p className="fs-5">Testimonials are short quotes from people who love your brand. It's a great way to convince customers to try your services.</p>
      <p className="fs-5">Miguel Rivera, Online Business Owner</p>
    </Col>
    <Col md={6}><img src='img/sittingmonkey.svg'/></Col>
    </Row>
  )
}

export default Test
